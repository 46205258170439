<template>
    <section class="detail-setup-quiz text-white">
        <div class="container-fluid text-left">
            <div class="row">
                <div class="col-3">
                    <div class="card mb-3 mt-5">
                        <div class="card-body">
                            <div class="form-group">
                                <div class="d-flex">
                                    <label type="button" @click="$bvModal.show('upload-image')">
                                        <div class="d-flex img-wrap">
                                            <img v-if="images_cover != '-' && images_cover != '' &&  images_cover != null && images_cover != 'null' && images_cover != undefined && images_cover != 'undefined'" :src="images_cover"  alt="" class="img-fluid">
                                            <img v-else src="@/assets/images/bg-cover-quiz.svg" alt="" class="img-fluid"/>
                                            <div class="img__description_layer">
                                                <p class="img__description">Click to change image</p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="txt-nama-quiz">Quiz Name*</label>
                                <input type="text" class="form-control" id="txt-nama-quiz" v-model="quiz_name">
                            </div>
                            <div class="form-group">
                                <label for="txt-deskripsi-quiz">Quiz Description</label>
                                <textarea v-model="description" class="form-control" id="txt-deskripsi-quiz" rows="4"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="dd-subject">Topic*</label>
                                <select name="subject" id="dd-subject" class="form-control" v-model="category_id" @change="changeLearningCategories()">
                                    <option v-if="categories.length == 0" disabled>Tidak ada data</option>
                                    <option
                                        v-for="(value, index) in newCategory"
                                        :key="index"
                                        :value="value._id"
                                    >{{value.category_name}}</option>
                                </select>
                            </div>
                            <div v-if="question_type !== 'Code Test'" class="form-group mb-1">
                                <label for="">Grade level :</label>
                                <!-- <div class="w-100">
                                    <button v-for="(value, index) in list_grade" :key="index" type="button" class="btn btn-sm px-4 mr-2 mb-1 mt-2" :class="grade_id == value._id ? 'btn-purple-pill': 'btn-grey-pill'" @click="setGrade(value._id, index)"><small :class="grade_id == value._id ? 'text-white ': ''">{{value.grade}}</small></button>
                                </div> -->
                                <div class="w-100 d-flex align-items-center">
                                    <select name="subject" id="dd-subject" class="form-control" v-on:change="changeGrade" v-model="dataQuiz.grade_edit">
                                        <option v-if="list_grade.length == 0" disabled>No data to show</option>
                                        <option
                                        v-for="(value, index) in list_grade"
                                        :key="index"
                                        :value="value.grade"
                                        >{{value.grade}}</option>
                                    </select>
                                </div>
                            </div>
                            <template v-if="list_grade.length > 0 && question_type !== 'Code Test'">
                                <div class="form-group" v-if="list_grade[indexGrade].sub_grade.length > 0">
                                    <label for="">Sub level :</label>
                                    <div class="w-100">
                                        <button v-for="(value, index) in list_grade[indexGrade].sub_grade" :key="index" type="button" class="btn btn-sm px-4 mr-2 mb-1 mt-2" :class="sub_grade_id == value._id ? 'btn-purple-pill': 'btn-grey-pill'" @click="setSubGrade(value._id)"><small :class="sub_grade_id == value._id ? 'text-white ': ''">{{value.sub_grade}}</small></button>
                                    </div>
                                </div>
                            </template>
                            <div v-if="question_type === 'Code Test'" class="form-group">
                                <span>Level</span>
                                <div class="w-100 mt-2">
                                    <button v-for="(value, index) in list_level" :key="index" type="button" class="btn btn-sm px-3 mr-2" :class="level_id == value._id ? 'btn-purple-pill': 'btn-grey-pill'" @click="setLevel(value._id)"><small :class="level_id == value._id ? 'text-white ': ''">{{value.level}}</small></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card">
                        <div class="card-body">
                            <h3>Import Pertanyaan</h3>
                            <h5 for="txt-deskripsi-quiz" class="my-2 font-weight-normal">Impor pertanyaan dari spreadsheet/excel</h5>
                            <p class="text-none">Harap unggah spreadsheet/excel yang mengikuti contoh template.</p>
                            <div class="excel d-flex mt-2 align-items-center">
                                <img src="@/assets/images/logo/excel-logo.png" alt="" class="logo-excel">
                                <p class="m-0 ml-2">Unduh Contoh Format</p>
                            </div>
                            <button class="btn btn-warning text-white btn-impor my-3" @click="$bvModal.show('impor-soal')"> <font-awesome-icon icon="fa-file-download" class="mr-1" />Import Question</button>
                        </div>
                    </div> -->
                </div>
                <div v-if="$store.state.listQuestion != null && $store.state.listQuestion.length > 0" class="col-9">
                    <div class="head-card">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="question-total d-flex align-items-center">
                                <img src="@/assets/images/icons/ic_document.svg" alt="question" class="border-none invert">
                                <h3 class="font-weight-normal ml-2 mr-auto">{{ this.$store.state.listQuestion.length }} Questions</h3>
                            </div>
                            <div>
                                <button type="button" class="btn btn-transparent btn-block my-2 text-center" @click="tambahPertanyaan()"><img src="@/assets/images/icons/ic_plus_purple.svg" class="mr-2"/><span style="font-size:18px" class="font-weight-bold">Add Question</span></button>
                            </div>
                        </div>
                    </div>
                    <div class="questions question-pg" v-for="(data, index) in $store.state.listQuestion" :key="index" v-katex:auto>
                        <div v-if="data.deleted == null" class="card" >
                            <div class="card-body py-3">
                                <div class="d-flex align-items-center mb-0">
                                    <div class="col-md-6">
                                        <span class="font-weight-normal fs-14">{{index + 1}}. {{ data.question_type == "Multiple Choice" || data.question_type == null || data.question_type == "" ? titleMultipleChoices : data.question_type == "True or False" ? titleTrueFalse : data.question_type == "Code Test" ? titleCode : data.question_type == "Code" ? titleCode : data.question_type == "Fill in the Blank" ? titleFillInTheBlank : titleMenjodohkan}} </span>
                                    </div>
                                    <div class="col-md-6 d-flex justify-content-end action-question">
                                        <div class="form-control mr-3 w-auto">
                                            <span class="mx-2 fs-12">
                                                {{ data.point ? data.point : '10' }} Point
                                            </span>
                                        </div>
                                        <div class="form-control mr-3 w-auto">
                                            <span class="mx-2 fs-12">
                                                {{ data.duration ? data.duration : '30' }} Seconds
                                            </span>
                                        </div>
                                        <button type="button" class="btn btn-action mr-3 d-flex align-items-center" @click="tambahPertanyaan(index)"><img src="@/assets/images/icons/Edit.svg" alt="edit" class="mr-1 invert"><span class="text-white fs-12">Edit</span></button>
                                        <button type="button" class="btn btn-action" @click="modalDeleteQuestion(data._id, index)"><img src="@/assets/images/icons/ic_delete_2.svg" alt="delete"></button>
                                    </div>
                                </div>
                                <div class="d-flex flex-column mb-3">
                                    <img v-if="data.image_url != null && data.image_url != ''" :src="data.image_url" :alt="data.image_url" class="image-url-style mr-2"/>
                                    <audio v-if="data.voice != null && data.voice != ''" :src="data.voice" controls></audio>
                                    <div v-if="data.video != null && data.video != ''" class="text-center w-100">
                                        <iframe width="50%" height="300" :src="data.video" title="YouTube video player" class="mt-2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    <div v-if="data.question_type === 'Code Test' || data.question_type === 'Code'" class="mt-3">
                                        <CodeEditor
                                            :language="data.code_language || 'py'"
                                            :value="data.code"
                                            readonly
                                        />
                                    </div>
                                    <p v-if="data.voice == null || data.voice == '' || data.video == null || data.video == ''" class="text-break mb-0 text-break fs-14-semibold"><span class="text-break ml-1" v-html="data.question"></span></p>
                                </div>
                                <div>
                                    <div>
                                        <p class="text-secondary-2 mb-0">Answer Choices</p>
                                        <hr class="mt-1">
                                    </div>
                                    <div class="answer container-fluid w-100">
                                        <div class="row">
                                            <div v-if="data.question_type == 'Multiple Choice' || data.question_type == 'Code Test' || data.question_type == 'Code' || data.question_type == 'Fill in the Blank' || data.question_type == null || data.question_type == ''" class="col-12 container-fluid text-left">
                                                    <div class="row justify-content-start px-3">
                                                        <div class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '1' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex align-items-center fs-12">A. <span class="text-break ml-1" id="options_answersA" v-html="data.option_1"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '2' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex align-items-center fs-12">B. <span class="text-break ml-1" id="options_answersB" v-html="data.option_2"></span></p>
                                                            </div>
                                                        </div>
                                                        <div v-if="data.option_3 != null && data.option_3 != ''" class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '3' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex align-items-center fs-12">C. <span class="text-break ml-1 " id="options_answersC" v-html="data.option_3"></span></p>
                                                            </div>
                                                        </div>
                                                        <div v-if="data.option_4 != null && data.option_4 != ''" class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '4' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex align-items-center fs-12">D.  <span class="text-break ml-1 " id="options_answersD" v-html="data.option_4"></span></p>
                                                            </div>
                                                        </div>
                                                        <!-- <vs-radio :color="data.correct_answer == '1' ? 'success' : 'danger'" class="col-6 d-flex justify-content-start" :vs-value="data.option_1">A. {{data.option_1}}</vs-radio>
                                                        <vs-radio :color="data.correct_answer == '2' ? 'success' : 'danger'" class="col-6 d-flex justify-content-start" :vs-value="data.option_2">B. {{data.option_2}}</vs-radio>
                                                        <vs-radio :color="data.correct_answer == '3' ? 'success' : 'danger'" class="col-6 d-flex justify-content-start" :vs-value="data.option_3">C. {{data.option_3}}</vs-radio>
                                                        <vs-radio :color="data.correct_answer == '4' ? 'success' : 'danger'" class="col-6 d-flex justify-content-start" :vs-value="data.option_4">D. {{data.option_4}}</vs-radio> -->
                                                    </div>
                                            </div>
                                            <div v-else-if="data.question_type == 'True or False'" class="col-12 container-fluid text-left">
                                                    <div class="row justify-content-start px-3">
                                                        <div class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '1' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_1"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 align-items-center justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '2' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_2"></span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                            <div v-else-if="data.question_type == 'Match'" class="col-12 container-fluid text-left">
                                                <table style="width:50%" border>
                                                    <tr>
                                                        <td style="width:20%">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span class="answer-def"></span>
                                                                </div>
                                                                <p v-if="data.img_option_1 == null || data.img_option_1 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.option_1"></span></p>
                                                                <img v-else :src="data.img_option_1" alt="img1" class="img-option ml-2">
                                                            </div>
                                                        </td>
                                                        <td style="width:20%">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span class="answer-def"></span>
                                                                </div>
                                                                <p v-if="data.img_option_2 == null || data.img_option_2 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_2"></span></p>
                                                                <img v-else :src="data.img_option_2" alt="img2" class="img-option ml-2">
                                                            </div>
                                                        </td>
                                                        <td style="width:20%">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span class="answer-def"></span>
                                                                </div>
                                                                <p v-if="data.img_option_3 == null || data.img_option_3 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_3"></span></p>
                                                                <img v-else :src="data.img_option_3" alt="img3" class="img-option ml-2">
                                                            </div>
                                                        </td>
                                                        <td style="width:20%">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span class="answer-def"></span>
                                                                </div>
                                                                <p v-if="data.img_option_4 == null || data.img_option_4 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_4"></span></p>
                                                                <img v-else :src="data.img_option_4" alt="img4" class="img-option ml-2">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style="width:20%">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span class="correct-active"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_1"></span></p>
                                                            </div>
                                                        </td>
                                                        <td style="width:20%">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span class="correct-active"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_2"></span></p>
                                                            </div>
                                                        </td>
                                                        <td style="width:20%">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span class="correct-active"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_3"></span></p>
                                                            </div>
                                                        </td>
                                                        <td style="width:20%">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span class="correct-active"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_4"></span></p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="col-9 mt-5 text-center">
                    <h1>You don't have any questions yet. Let's do it now!</h1>
                    <button  v-if="$store.state.dataQuiz.quiz_name != null && $store.state.dataQuiz.quiz_name != '' && $store.state.dataQuiz.category_id != null && $store.state.dataQuiz.category_id != ''" type="button" class="btn btn-primary btn-lg mt-4 my-2 text-center" @click="tambahPertanyaanNew()"><img src="@/assets/images/plus-white.svg" class="mr-2"/>Tambahkan Pertanyaan</button>
                    <button  v-else type="button" class="btn btn-primary disabled btn-lg mt-4 my-2 text-center" style="cursor: not-allowed"><img src="@/assets/images/plus-white.svg" class="mr-2"/>Tambahkan Pertanyaan</button>
                </div>
            </div>
            <b-overlay
            :show="$store.state.loadingUpdate"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-large
            rounded="sm"
            style="height : auto"
            class="position-fixed"
            no-wrap
            />
        </div>
        <!-- MODAL UPLOAD IMG CVR -->
        <b-modal id="upload-image" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
            <!-- <div class="my-4 mx-4">
                <div class="text-right">
                    <button class="btn btn-close d-flex justify-content-center align-items-center" @click="$bvModal.hide('upload-image')"><b-icon icon="x"></b-icon></button>
                </div>
            </div> -->
            <div class="position-relative mt-2">
                <button class="btn btn-close d-flex justify-content-center align-items-center position-absolute close-image" @click="$bvModal.hide('upload-image')"><b-icon icon="x"></b-icon></button>
                <vs-tabs :color="colorx">
                    <vs-tab label="Gallery">
                         <div class="con-tab-ejemplo gallery mt-2">
                            <label for="search">
                                <input type="text" v-model="filterThumb" class="search-bar text-white" placeholder="Find thumbnail" @change="searchImage(filterThumb)">
                            </label>
                            <div v-if="unsplashImage.length == 0" class="container-fluid overflow-auto container-thumbnail mt-3">
                                <div v-if="masterThumbnails.length > 0" class="row">
                                    <div v-for="(data, index) in masterThumbnails" :key="index" class="col-md-3 mb-3 px-0">
                                        <div class="mr-2 ml-2">
                                            <button class="btn-thumbnail" @click="fromThumbnail(data.file, data.name)">
                                                <img :src="data.file" :alt="data.name" class="img-fluid thumbnail-img"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="h-100 d-flex align-items-center justify-content-center">
                                    <p class="mb-0 text-muted">Image not found</p>
                                </div>
                            </div>
                            <div v-else class="container-fluid overflow-auto container-thumbnail mt-3">
                                <b-card-group columns v-if="unsplashImage.length > 0">
                                    <b-card v-for="(data, index) in unsplashImage" :key="index" class="px-0" body-class="p-0 m-0">
                                        <button class="btn-thumbnail" @click="fromThumbnail(data.urls.thumb, data.description)" style="width: 100%; height: 100%;">
                                            <img :src="data.urls.thumb" class="thumbnail-img" style="width: 100%; height: 100%;"/>
                                        </button>
                                    </b-card>
                                </b-card-group>
                                <div v-else class="h-100 d-flex align-items-center justify-content-center">
                                    <p class="mb-0 text-muted">Image not found</p>
                                </div>
                            </div>
                        </div>
                    </vs-tab>
                    <vs-tab label="Upload">
                         <div class="con-tab-ejemplo" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent>
                            <b-alert variant="danger" :show="showAlertImg" fade dismissible>
                                {{ responseValidateImg }}
                            </b-alert>
                            <label @drop="dragImage($event)" class="btn btn-impor-custom w-100 d-flex flex-column py-5 align-items-center text-center mt-3">
                                <div v-if="images_cover_before != '-' && images_cover_before != '' &&  images_cover_before != null && images_cover_before != undefined" class="h-100 d-flex align-items-center">
                                    <div>
                                        <img :src="images_cover_before"  alt="" class="img-form">
                                        <small class="my-auto ml-2">{{ typeof imageFileBefore === "string" ? 'cover_image.jpg' : imagenameBefore }}</small>
                                    </div>
                                </div>
                                <div v-else class="h-100 d-flex align-items-center">
                                    <div>
                                        <img src="@/assets/images/logo/paper_impor.svg" alt="">
                                        <h4 class="font-weight-normal mt-2 text-blue">Choose image</h4>
                                        <h5 class="font-weight-normal text-muted mt-1">or drag & drop here</h5>
                                    </div>
                                </div>
                                <input type="file" style="display: none" accept="image/jpeg, image/png" @change="imageUpload($event)"/>
                            </label>
                        </div>
                    </vs-tab>
                </vs-tabs>
                <hr style="background: #2d2d2d;">
                <div class="float-right">
                    <button class="btn btn-secondary btn-md btn-grey mr-2" @click="cancelImage"><p class="mb-0 mx-2">Cancel</p></button>
                    <button class="btn btn-purple btn-md text-white" @click="saveImage"><p class="mb-0 mx-2">Use</p></button>
                </div>
            </div>
        </b-modal>

        <!-- MODAL IMPOR -->
        <b-modal id="impor-soal" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
            <div class="form-row">
                <b-form-group class="col-md-12">
                    <b-alert variant="danger" :show="showAlert" fade dismissible>
                        {{responseValidate}}
                    </b-alert>
                </b-form-group>
            </div>
            <div class="d-flex justify-content-between my-4 mx-4">
                <div class="text-left">
                    <h3 class="font-weight-bold">Impor pertanyaan dari spreadsheet/excel</h3>
                    <h5 class="mb-0 mt-2 font-weight-normal">Harap unggah spreadsheet/excel yang mengikuti contoh template.</h5>
                </div>
                <div class="text-right">
                    <button class="btn btn-close d-flex justify-content-center align-items-center" @click="$bvModal.hide('impor-soal')"><b-icon icon="x"></b-icon></button>
                </div>
            </div>
            <div class="mx-4">
                <label class="btn btn-impor-custom w-100 d-flex flex-column py-5 align-items-center text-center">
                    <div v-if="filename == ''">
                        <img src="@/assets/images/logo/paper_impor.svg" alt="">
                        <h4 class="font-weight-normal mt-2 text-blue">Choose file</h4>
                        <h5 class="font-weight-normal text-muted mt-1">or drag & drop here</h5>
                    </div>
                    <div v-else>
                        <img src="@/assets/images/logo/excel.png" alt="">
                    </div>
                    <input type="file" style="display: none" ref="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="fileUpload($event)"/>
                    <h5 class="mt-3">{{ typeof formData.file === "string" ? formData.file : filename }}</h5>
                </label>
                <div class="d-flex align-items-center justify-content-between py-3">
                    <div class="d-flex align-items-center">
                        <img src="@/assets/images/logo/excel.png" alt="" class="img-logo">
                        <h4 class="font-weight-normal ml-2">Unduh contoh format</h4>
                    </div>
                    <div class="d-flex align-items-center">
                        <button class="btn btn-secondary text-dark">Cancel</button>
                        <button v-if="filename == ''" class="btn btn-secondary text-white ml-2" style="cursor : not-allowed;" disabled>Impor</button>
                        <button v-else class="btn btn-warning text-white ml-2">Impor</button>
                    </div>
                </div>
            </div>
        </b-modal>

        <!-- DELETE QUESTION -->
        <b-modal id="delete-question" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="sm">
            <div class="text-center my-3">
                <img src="@/assets/images/icons/modal_warning.svg" alt="Delete Question">
                <div class="mt-3">
                    <h5 class="font-weight-bold">Are you sure</h5>
                    <h5 class="font-weight-bold">to delete this question ?</h5>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button class="btn btn-secondary text-white mr-2" @click="$bvModal.hide('delete-question')"><p class="mb-0 font-weight-bold">Cancel</p></button>
                    <button class="btn btn-primary btn-purple-nb" @click="deleteQuestion()"><p class="mx-2 mb-0 font-weight-bold">Yes</p></button>
                </div>
            </div>
        </b-modal>
        <b-overlay :show="loadingUpdate" no-wrap spinner-variant="primary" spinner-type="grow" rounded="sm" style="position:fixed !important;">
        </b-overlay>
    </section>
</template>
<script>
import $ from "jquery"
import {AlertUtils} from '@/mixins/AlertUtils';
import { mapGetters } from "vuex";

import {getQuizCreatorById} from '@/services/quiz-creator/quiz-creator.service'
import {getCategoriesAll} from '@/services/quiz-categories/quiz_categories.service'
import {getMasterThumbnails} from '@/services/master-thumbnails/master-thumbnails.service'
// import {getMasterGradeAll} from '@/services/master-grades/master-grades.service'
import {getLearningCategories} from '@/services/learning-categories/learning-categories.service'
import { getQuestionCreator } from '@/services/question-creator/question_creator.service'
import { getGrades } from '@/services/generative-ai/generative-ai.service'
import { getSearchImage } from "@/services/course/course.service";

import CodeEditor from '@/components/code-editor/CodeEditor';

export default {
    components: {
        CodeEditor,
    },
    mixins: [AlertUtils],
    layouts: {
        default: '~/views/layouts/empty.vue',
    },
    data(){
        return {
            dataQuiz : {
                category_id: null,
                learning_category_id: null,
                grade_id: null,
                grade_edit: null,
                level: null,
                sub_grade_id: null,
                quiz_name: null,
                author:null,
                images_cover:null,
                categories_quiz: {
                    category_name: null
                }
            },
            question_type: null,
            grade_id: "",
            sub_grade_id: "",
            level_id: "",
            list_grade: [],
            list_level: [],
            categories: [],
            dataQuestion:[],
            learnings:[],
            listQuestion: [],
            newQuestion: [],
            oldQuestion: [],
            realQuestion: [],
            deletedData : [],
            newIdQuiz: null,
            responseValidate: "",
            showAlert: false,
            responseValidateImg: "",
            filterThumb : null,
            showAlertImg: false,
            filename: "",
            imagename: "",
            formData: {
                file: "",
            },
            imageFile : "",
            imagenameBefore: "",
            imageFileBefore: "",
            images_cover_before: "",
            colorx: 'rgb(188, 59, 242)',
            thumbnail: [ "reproduksi.svg","metabolisme.svg", "tkj.svg", "sosiologi.svg", "geografi.svg", "Digital Marketing.svg", "Bahasa Mandarin.svg", "Bahasa Jepang.svg" ],
            quiz_name: null,
            description: null,
            category_id: null,
            images_cover: null,
            index_question_delete: null,
            id_question_delete: null,
            masterThumbnails : [],
            titleMultipleChoices : "Multiple Choice",
            titleTrueFalse : "True or False",
            titleCode: "Code Test",
            titleFillInTheBlank : "Short Answers",
            titleMenjodohkan : "Menjodohkan",
            users:{},
            indexGrade: 0,

            // Unsplash
            perPageUnsplash: 30,
            pageUnsplash: 1,
            unsplashImage : [],
        }
    },
    watch: {
        quiz_name(newQuizName) {
            localStorage.quiz_name_edit = newQuizName;
        },
        description(newQuizDescription) {
            localStorage.description_edit = newQuizDescription;
        },
        images_cover(newImagesCover) {
            localStorage.images_cover_edit = newImagesCover;
        },
        category_id(newCategoryID) {
            localStorage.category_id_edit = newCategoryID;
        },
        grade_id(newGradeId) {
            localStorage.grade_id_edit = newGradeId;
        },
        sub_grade_id(newSubGradeId){
            localStorage.sub_grade_id_edit = newSubGradeId;
        },
        level_id(newLevelId){
            localStorage.level_edit = newLevelId;
        }
    },

    mounted(){
        // this.question_type = this.$store.state.listQuestion[0];

        this.getQuestionCreatorById()
        this.setSideBar()
        this.getThumbnail()
        this.getLevels();

        if(localStorage.getItem('users')){
            this.users = JSON.parse(localStorage.getItem('users'))
        }

        if(localStorage.quiz_name_edit || localStorage.description_edit || localStorage.images_cover_edit || localStorage.category_id_edit || localStorage.grade_id_edit || localStorage.level_edit){
            if(localStorage.quiz_name_edit){
                this.quiz_name = localStorage.quiz_name_edit
            }
            if(localStorage.description_edit){
                this.description = localStorage.description_edit
            }
            if(localStorage.images_cover_edit){
                this.images_cover = localStorage.images_cover_edit
            }
            if(localStorage.category_id_edit){
                this.category_id = localStorage.category_id_edit
                if(this.category_id == '1' || this.category_id == '2' || this.category_id == '3' || this.category_id == '4' || this.category_id == '5' ) {
                    this.question_type = "Code Test"
                }
            }
            if(localStorage.grade_id_edit){
                this.grade_id = localStorage.grade_id_edit
            }
            if(localStorage.sub_grade_id_edit){
                this.sub_grade_id = localStorage.sub_grade_id_edit
            }
            if(localStorage.level_edit){
                this.level_id = localStorage.level_edit;
            }
            this.getMasterGrade(this.grade_id)
            this.getCategories()
        } else {
            // this.getDetailQuiz(id)
            this.cekId()
        }

        this.getLearningCategories(this.$store.state.dataQuiz.category_id)
        $("section.detail-setup-quiz").parent().addClass('m-0');
    },

    computed: {
        searchThumb() {
            if (this.filterThumb) {
                return this.masterThumbnails.filter((item) => {
                return this.filterThumb
                    .toLowerCase()
                    .split(" ")
                    .every(
                    (v) =>
                        item.name.toLowerCase().includes(v)
                    );
                });
            } else {
                return this.masterThumbnails;
            }
        },

        newCategory(){
            return this.categories
        },

        ...mapGetters({
            loadingUpdate : 'loadingUpdate'
        }),
    },
    methods: {
        changeGrade(event){
            const selectedIndex = event.target.selectedIndex;

            this.setGrade(this.list_grade[selectedIndex]._id, selectedIndex)
        },
        cekId(){
            const id = this.$route.query.key
            if(id){
                this.getDetailQuiz(id)
            }
        },
        fileUpload(e){
            const fileSize = e.target.files[0].size;
            var file = e.target.files[0];
            if (e.lengthComputable) {
                console.log(e.loaded / e.total * 100);
            }
            if (
                file.type ==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type == "application/vnd.ms-excel"
            ) {
                if (fileSize > 25000000) {
                    this.showAlert = true;
                    this.responseValidate = "Ukuran file melebihi 25MB";
                } else {
                    this.showAlert = false;
                    this.formData.file = e.target.files[0];
                    this.filename = e.target.files[0].name
                        ? e.target.files[0].name
                        : "Undefined";
                }
            } else {
                this.responseValidate = "The file must be in XLS or XLSX format";
                this.showAlert = true;
                return false;
            }
        },
        getDetailQuiz(id){

            getQuizCreatorById(id)
            .then(response => {
                if(response.status && response.status == true){

                    // this.$store.state.dataQuiz = response.data[0]
                    this.dataQuiz = response.data[0]
                    localStorage.quiz_name_edit = this.dataQuiz.quiz_name
                    localStorage.description_edit = this.dataQuiz.description != undefined ? this.dataQuiz.description : ""
                    localStorage.images_cover_edit = this.dataQuiz.images_cover
                    localStorage.category_id_edit = this.dataQuiz.category_id
                    localStorage.grade_id_edit = this.dataQuiz.grade_id != undefined ? this.dataQuiz.grade_id : ''
                    localStorage.sub_grade_id_edit = this.dataQuiz.sub_grade_id != undefined ? this.dataQuiz.sub_grade_id : ''
                    localStorage.level_edit = this.dataQuiz.grade_id != undefined ? this.dataQuiz.grade_id : '';

                    if(localStorage.quiz_name_edit){
                        this.quiz_name = localStorage.quiz_name_edit
                    }
                    if(localStorage.description_edit){
                        this.description = localStorage.description_edit
                    }
                    if(localStorage.images_cover_edit){
                        this.images_cover = localStorage.images_cover_edit
                    }
                    if(localStorage.category_id_edit){
                        this.category_id = localStorage.category_id_edit
                    }
                    if(localStorage.grade_id_edit){
                        this.grade_id = localStorage.grade_id_edit
                    }
                    if(localStorage.sub_grade_id_edit){
                        this.sub_grade_id = localStorage.sub_grade_id_edit;
                    }
                    if(this.category_id == "64215d46fb6ea4ab650b1307") {
                        this.question_type = 'Code Test'
                        if(this.dataQuiz.topic) {
                            localStorage.levelAi = this.dataQuiz.topic
                            this.category_id = this.dataQuiz.topic
                        }
                        this.getCategories()
                    } else {
                        this.getCategories()
                    }

                    if(this.dataQuiz.grade_id){
                        this.level_id = this.dataQuiz.grade_id
                        this.getMasterGrade(this.dataQuiz.grade_id)
                    }
                }
                this.getLearningCategories(this.$store.state.dataQuiz.category_id)
            }).catch(error => {
                console.log(error)
            })
        },
        getCategories(){
            if (this.question_type === 'Code Test') {
                this.categories = [
                    {
                        _id: '1',
                        category_name: 'Python',
                    },
                    {
                        _id: '2',
                        category_name: 'JavaScript',
                    },
                    {
                        _id: '3',
                        category_name: 'Java',
                    },
                    {
                        _id: '4',
                        category_name: 'C',
                    },
                    {
                        _id: '5',
                        category_name: 'C++',
                    },
                ];
            } else {
                getCategoriesAll()
                .then(response => {
                    if(response.status && response.status == true){
                        this.categories = response.data
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        getLevels() {
            this.list_level = [
                {
                    _id: '1',
                    level: 'Basic',
                },
                {
                    _id: '2',
                    level: 'Intermediate',
                },
                {
                    _id: '3',
                    level: 'Expert',
                },
            ];
        },
        getMasterGrade(val){
             getGrades()
                .then((response) => {
                    if(response.status == true) {
                        this.list_grade = response.data
                        if(val != undefined && val != "" && val != null && val != "undefined"){
                            this.indexGrade = this.list_grade.findIndex(x => x.grade === val || x._id === val)
                            if(this.indexGrade != -1) {
                                this.dataQuiz.grade_edit = this.list_grade[this.indexGrade].grade;
                            }
                        }
                    }
                })
                .catch((err) => {
                    this.$vs.loading.close();
                    console.log("data err", err);
                });
        },
        getLearningCategories(id){
            getLearningCategories(id)
            .then(response => {
                if(response.status && response.status == true){
                    this.learnings = response.data
                }
            }).catch(error => {
                console.log(error)
            })
        },
        changeLearningCategories(){
            this.learnings = []
            this.getLearningCategories(this.$store.state.dataQuiz.category_id)
        },
        setSideBar(){
            this.$store.commit('IS_SIDEBAR_ACTIVE', false);
        },

        tambahPertanyaan(index){
            var id = this.$route.query.key
            if(id != undefined){
                if(index == undefined ){
                    this.$store.state.updateData._id = null
                    this.$store.state.updateData.question = null
                    this.$store.state.updateData.option_1 = null
                    this.$store.state.updateData.option_2 = null
                    this.$store.state.updateData.option_3 = null
                    this.$store.state.updateData.option_4 = null
                    this.$store.state.updateData.correct_answer = null
                    this.$store.state.updateData.image_url = null
                    this.$store.state.updateData.voice = null
                    this.$store.state.updateData.video = null

                    this.$store.state.opsi1Update = null
                    this.$store.state.opsi2Update = null
                    this.$store.state.opsi3Update = null
                    this.$store.state.opsi4Update = null

                    localStorage.setItem('backUpUpdateQuestion', JSON.stringify(this.$store.state.listQuestion))
                    this.$router.push({ name: 'Update-Question-Creator', query: {key:id}})
                } else{
                    localStorage.setItem('backUpUpdateQuestion', JSON.stringify(this.$store.state.listQuestion))
                    this.$router.push({ name: 'Update-Question-Creator', query: {key:id, index: index+1}})
                }
            } else {
                if(index == undefined ){
                    this.$router.push({ name: 'Create-Question-Creator'})
                } else{
                    this.$router.push({ name: 'Create-Question-Creator', query: {index: index+1}})
                }
            }
            let setData = localStorage.getItem("listQuestionCreator");
            if(!setData){
                localStorage.setItem('listQuestionCreator', JSON.stringify(this.$store.state.listQuestion))
            }

        },

        getThumbnail(){
            getMasterThumbnails()
            .then((response) =>{
                if(response.status && response.status == true){
                    this.masterThumbnails = response.data
                }
            })
        },

        tambahPertanyaanNew(){
            localStorage.removeItem('listQuestionCreator')
            this.$router.push({ name: 'Create-Question-Creator'})
        },

        // Set data question, while user cancelled add new questions
        getQuestionCreatorById(){
            const id = this.$route.query.key
            if(id != undefined){
                getQuestionCreator(id)
                .then((response) =>{
                    if (response.status && response.status == true){
                        this.realQuestion = response.data;
                        let dataQuestion = localStorage.getItem("listQuestionCreator");

                        // let parseDataOld = []
                        // parseDataOld = this.$store.state.listUpdateQuestion

                        // let dataNew = localStorage.getItem("updateQuestionNew");
                        // localStorage.removeItem("updateQuestionNew")

                        // if(dataNew){
                        //     let parseDataNew = JSON.parse(dataNew)

                        //     parseDataNew.forEach((e) => {
                        //         const splice = parseDataOld.findIndex(x => x._id == e._id) || null
                        //         console.log(splice)

                        //         if(splice != null) {
                        //             parseDataOld.splice(splice, 1)
                        //             localStorage.setItem("listQuestionCreator", JSON.stringify(parseDataOld))
                        //         }
                        //     })
                        //     this.$store.state.listQuestion = JSON.parse(localStorage.listQuestionCreator);
                        // } else {
                        //     if(dataQuestion){
                        //         this.$store.state.listQuestion = JSON.parse(dataQuestion);
                        //     } else {
                        //         this.$store.state.listQuestion = this.realQuestion;
                        //     }
                        // }
                        if(this.$store.state.canceledUpdate == true){
                            this.$store.state.listQuestion = JSON.parse(localStorage.backUpUpdateQuestion)
                            localStorage.setItem('listQuestionCreator', JSON.stringify(this.$store.state.listQuestion))
                            this.$store.state.canceledUpdate = false
                        } else {
                            if(dataQuestion){
                                this.$store.state.listQuestion = JSON.parse(dataQuestion);
                            } else {
                                this.$store.state.listQuestion = this.realQuestion;
                            }
                        }



                    }
                })
            } else {
                let dataQuestion = localStorage.getItem("listQuestionCreator");
                this.$store.state.listQuestion = JSON.parse(dataQuestion);

                // let isArray = Array.isArray(dataQuestion)
                // if(isArray == false){
                //     this.$store.state.listQuestion.push(JSON.parse(dataQuestion));
                // } else {
                //     this.$store.state.listQuestion = JSON.parse(dataQuestion);
                // }
            }
        },
        deleteItem(id, index){
            if(id.toString().length <= 5){
                this.$store.state.listQuestion.splice(index,1)
                localStorage.setItem('listQuestionCreator', JSON.stringify(this.$store.state.listQuestion))
            } else {
                const updateQ = this.$store.state.listQuestion.find(e => e._id === id)
                this.deletedData.push(updateQ);
                this.$store.state.listQuestion.splice(index,1)
                // updateQ.deleted = true
                // this.$store.state.listQuestion.push(updateQ)
                localStorage.setItem('deletedEditQuestion', JSON.stringify(this.deletedData))
                localStorage.setItem('listQuestionCreator', JSON.stringify(this.$store.state.listQuestion))
            }
        },

        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return "0 Bytes";

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
        },

        convertImage(file, form) {
            var vm = this;
            var reader = new FileReader();
            reader.onloadend = function () {
                switch (form) {
                    case 'images_cover':
                        // vm.$store.state.dataQuiz.images_cover = reader.result
                        vm.images_cover_before = reader.result
                        break;
                    default:
                        break;
                }
            };
            reader.readAsDataURL(file);
        },

        imageUpload(e) {
            let size = e.target.files[0].size;

            let type = e.target.files[0].type;
            if (
                type == "image/png" ||
                type === "image/jpeg"
            ) {
                if (size > 1000000) {
                    this.alertFail(this.$t("file-size-max-1mb"));
                } else {
                    this.showAlertImg = false;
                    this.imagenameBefore = e.target.files[0].name
                        ? e.target.files[0].name
                        : "Undefined";
                    this.imageFileBefore = e.target.files[0];
                    this.images_cover_before = URL.createObjectURL(this.imageFileBefore)
                    this.convertImage(this.imageFileBefore, 'images_cover')
                }
            } else {
                this.showAlertImg = true;
                this.responseValidateImg = "File harus bertipe PNG, JPG, JPEG";
            }
        },

        dragImage(e){
            const file = [...e.dataTransfer.files];

            let size = file[0].size;

            let type = file[0].type;
            if (
                type == "image/png" ||
                type === "image/jpeg"
            ) {
                if (size > 1000000) {
                    this.alertFail(this.$t("file-size-max-1mb"));
                } else {
                    this.showAlertImg = false;
                    this.imagenameBefore = file[0].name
                        ? file[0].name
                        : "Undefined";
                    this.imageFileBefore = file[0];
                    this.images_cover_before = URL.createObjectURL(this.imageFileBefore)
                    this.convertImage(this.imageFileBefore, 'images_cover')
                }
            } else {
                this.showAlertImg = true;
                this.responseValidateImg = "File harus bertipe PNG, JPG, JPEG";
            }
        },

        saveImage(){
            this.imagename = this.imagenameBefore
            this.imageFile = this.imageFileBefore
            this.images_cover = this.images_cover_before
            // this.$store.state.dataQuiz.images_cover = this.images_cover_before

            this.imagenameBefore = ""
            this.imageFileBefore = ""
            this.images_cover_before = ""
            this.$bvModal.hide('upload-image')
        },

        cancelImage(){
            this.imagenameBefore = ""
            this.imageFileBefore = ""
            this.images_cover_before = ""
            this.showAlertImg = false;
            this.$bvModal.hide('upload-image')
        },

        fromThumbnail(image, name){
            this.imagenameBefore = name
            this.imageFileBefore = image
            this.images_cover_before = image
            // this.images_cover = this.images_cover_before
        },

        back(){
            localStorage.removeItem("quiz_name_edit")
            localStorage.removeItem("description_edit")
            localStorage.removeItem("images_cover_edit")
            localStorage.removeItem("category_id_edit")

            this.$router.push({ name : 'My-Quiz' })
        },
        setGrade(val, index){
            this.grade_id = val
            this.indexGrade = index
            this.dataQuiz.grade_edit = this.list_grade[index].grade
            if(this.list_grade[index].sub_grade.length > 0) {
                this.sub_grade = this.list_grade[index].sub_grade[0]._id
            }else{
                this.sub_grade = ''
            }
        },
        setSubGrade(val){
            this.sub_grade_id = val
        },
        setLevel(val) {
            this.level_id = val;
        },
        modalDeleteQuestion(id, index){
            if(index != null){
                this.index_question_delete = null;
            }

            if(id != null){
                this.id_question_delete = null;
            }

            this.index_question_delete = index;
            this.id_question_delete = id;
            this.$bvModal.show('delete-question')
        },

        deleteQuestion(){
            this.deleteItem(this.id_question_delete, this.index_question_delete)
            this.$bvModal.hide('delete-question')
        },

        searchImage(input){
            var vm = this

            getSearchImage(input, vm.perPageUnsplash, vm.pageUnsplash)
            .then((response) => {
                if(response.status){
                    vm.unsplashImage = response.data.results
                } else {
                    vm.unsplashImage = []
                }
            }).catch((err) => {
                vm.unsplashImage = []
                console.log("data err", err);
            });
        }
    }
}
</script>

<style scoped>
    table, th, td {
        border: 0;
    }

    tr,td{
        padding-top: 1rem;
    }

    .invert{
        filter: invert(100%);
    }

    .form-control{
        background-color: #222222;
        color: white;
        border-color: #383838;
        box-shadow: none;
    }

    .btn-transparent{
        box-shadow: none;
    }

    .btn-action{
        background-color: #2D2D2D;
    }

    .action-question .form-control{
        background-color: transparent;
        border: 1px solid #2D2D2D;
        color: white
    }

    .correct-active{
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 50%;
        display: inline-block;
        background-color: #40BE45;
    }

    .answer-def{
        height: 1.125rem;
        width: 1.125rem;
        border-radius: 50%;
        display: inline-block;
        background-color: #D9D9D9;
    }

    .img-option{
        height: 3.5rem;
        width: 3.75rem;
        border-radius: 6px;
    }

    .image-url-style{
        width: 6rem;
        height: 6rem;
    }

    .incorrect-active{
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 50%;
        display: inline-block;
        background-color: #EB5757;
    }

    .label {
        font-size: 1.15rem;
    }

    .img-form {
        width: 70px;
        height: 70px;
    }

    .text-blue{
        color: #2B87E3;
    }

    .text-none{
        font-size: 14px;
        color: #636363;
    }

    .head-card {
        height: 3rem;
    }

    .row .col-3, .row .col-9 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .container-image {
        position: relative;
        width: 55%;
    }
    .container-image:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    .btn-impor-custom{
        border : 2px dashed #2D2D2D;
        height: 20rem;
        border-radius: 10px;
    }

    .btn-close{
        background-color: #EDEDED;
        border-radius: 50%;
        width: 35px;
        height: 35px;
    }
    .container-image img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .select-badge{
        width: auto;
        border: 0;
        border-radius: 15px;
        background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
        background-position: calc(100% - 0.75rem) center !important;
        background-color: #E5E5E5;
        -moz-appearance:none !important;
        -webkit-appearance: none !important;
        appearance: none !important;
        padding-left: 1rem;
        padding-right: 2rem;
    }

    .questions {
        margin-bottom: 1rem;
    }

    .img-logo{
        width: 1.5rem;
        height: 1.5rem;
    }

    .questions .card {
        border-radius: 10px;
    }

    .questions .card .card-body {
        padding-left: 2.25rem;
    }

    .logo-excel{
        width: 18px;
        height: 18px;
    }

    .btn-impor{
        border-radius: 25px;
    }

    hr{
        background : #8C8C8C;
    }

    .text-secondary-2{
        color: #8C8C8C
    }

    .card {
        border-radius: 10px;
    }

    .search-bar {
        height: 3rem;
        width: 100%;
        border-radius: 25px;
        background:transparent;
        border: 1px solid #2D2D2D;
        padding-left: 48px !important;
        padding-right: 20px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box;
    }

    .gallery label {
        position: relative;
        width: 100%;
        height: 40px;
        border-radius: 25px !important;
        z-index: 2;
    }

    .gallery label:before {
        content: "";
        position: absolute;
        left: 1rem;
        top: 10px;
        bottom: 0;
        width: 40px;
        background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
        background-size: 20px;
    }

    .thumbnail-img {
        border-radius: 10px 10px 10px 10px
    }

    .btn-thumbnail{
        background-color: transparent;
        border: none;
    }

    .btn-thumbnail:focus img {
        border: 3px solid #BC3BF2;
        border-radius: 10px;
    }

    .container-thumbnail{
        height: 28rem;
    }

    .container-thumbnail::-webkit-scrollbar {
        width: 6px;
    }

        /* Track */
    .container-thumbnail::-webkit-scrollbar-track {
        background: #636363;
        border-radius: 4px;
    }

        /* Handle */
    .container-thumbnail::-webkit-scrollbar-thumb {
        background: #B6B6B6;
        border-radius: 4px;
        background-clip: padding-box;
    }

        /* Handle on hover */
    .container-thumbnail::-webkit-scrollbar-thumb:hover {
        background: #888;
    }

    .close-image{
        right : 5px;
        z-index: 100;
    }

    .btn-grey{
        background-color: #2D2D2D !important;
        color: white;
        border: 0px !important;
    }

    .img-wrap{
        position: relative;
    }

    .img-wrap img{
        border-radius: 10px;
    }

    .img__description_layer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 10px;
        color: #fff;
        visibility: hidden;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        /* transition effect. not necessary */
        transition: opacity .2s, visibility .2s;
    }

    .img-wrap:hover .img__description_layer {
        visibility: visible;
        opacity: 1;
    }

    .img__description {
        transition: .2s;
        transform: translateY(1em);
    }

    .img-wrap:hover .img__description {
        transform: translateY(0);
    }

    .btn-purple-pill {
        color: white;
        background-color: #6D75F6;
        border: 1px solid #6D75F6;
        height: 1.9rem;
        border-radius: 4px;
    }

    .fs-12{
        font-size: 12px;
    }
    .fs-14-semibold{
        font-size: 14px;
        font-weight: 600;
    }

    .btn-purple {
        background-color: #6D75F6 !important;
        border: 1px solid #6D75F6 !important;
        color: white;
    }

    .btn-grey-pill {
        background-color: transparent;
        border: 1px solid #2D2D2D;
        color: #8C8C8C !important;
        height: 1.9rem;
        border-radius: 4px;
    }

    .btn-transparent {
        background-color: transparent;
        color: #6D75F6;
        display: flex;
        align-items: center;
    }
</style>

<style>
    button, .btn{
        box-shadow: none;
    }
    p{
        margin-bottom: 0px;
    }

    #upload-image .modal-content, #delete-question .modal-content{
        background-color: #1F1F1F;
        color: white;
        border-radius: 6px;
    }
</style>